<template>
  <div>
    <v-select
      outlined
      v-model="period"
      :items="periods"
      clearable
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Pour la période"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {
      periods: [
        {
          id: 15,
          name: "2 dernières semaines",
        },
        {
          id: 30,
          name: "30 derniers jours",
        },
        {
          id: 60,
          name: "2 derniers mois",
        },
        {
          id: 90,
          name: "3 derniers mois",
        },
        {
          id: 182,
          name: "6 derniers mois",
        },
        {
          id: 365,
          name: "12 derniers mois",
        },
      ],
    };
  },
  computed: {
    period: {
      get() {
        return this.$store.getters[`${this.department}/getPeriod`];
      },
      set(e) {
        this.$store.dispatch(`${this.department}/updatePeriod`, e);
      },
    },
  },
};
</script>

<style></style>
