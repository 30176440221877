<template>
  <div>
    <v-row class="d-flex ma-3" v-if="['regie'].includes(department)">
      <v-col cols="6">
        <site-groups-filter store="regie" />
      </v-col>
      <v-col cols="6">
        <sites-filter store="regie" />
      </v-col>
      <v-col cols="3">
        <period-filter
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          :department="department"
        ></period-filter>
      </v-col>
      <v-col cols="6">
        <text-filter
          :menu-props="{ maxHeight: '300', zIndex: 13 }"
          :department="department"
          class="mt-2"
        ></text-filter>
      </v-col>
      <v-col cols="3" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="disabled" outlined color="secondary" />
      </v-col>
    </v-row>
    <v-row class="d-flex ma-3" v-else>
      <v-col cols="3">
        <period-filter
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          :department="department"
        ></period-filter>
      </v-col>
      <v-col cols="3">
        <text-filter
          class="mt-2"
          :department="department"
          :menu-props="{ maxHeight: '300', zIndex: 13 }"
        ></text-filter>
      </v-col>
      <v-col cols="3">
        <ingredient-filter
          class="mt-2"
          label="Ingrédients"
          :withGroups="false"
          storeGetter="getMarmitonIngredientsToInclude"
          storeUpdater="updateMarmitonIngredientsToInclude"
        />
      </v-col>
      <v-col cols="3">
        <flavour-filter />
      </v-col>
      <v-col cols="3">
        <age-filter />
      </v-col>
      <v-col cols="3">
        <region-filter />
      </v-col>
      <v-col cols="6" class="d-flex">
        <v-spacer />
        <apply-filters
          :disabled="disabled"
          outlined
          :store="department"
          color="secondary"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import SitesFilter from "@/components/common/filters/SitesFilter";
import SiteGroupsFilter from "@/components/common/filters/SiteGroupsFilter";
import IngredientFilter from "@/components/regie/common/filters/IngredientFilter";
import AgeFilter from "@/components/regie/visitors-info/filters/AgeFilter";
import FlavourFilter from "@/components/regie/visitors-info/filters/FlavourFilter.vue";
import PeriodFilter from "@/components/regie/visitors-info/filters/PeriodFilter.vue";
import RegionFilter from "@/components/regie/visitors-info/filters/RegionFilter.vue";
import TextFilter from "@/components/regie/visitors-info/filters/TextFilter.vue";

export default {
  name: "Filters",
  components: {
    AgeFilter,
    ApplyFilters,
    FlavourFilter,
    IngredientFilter,
    PeriodFilter,
    RegionFilter,
    SiteGroupsFilter,
    SitesFilter,
    TextFilter,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {
      disabled: true,
    };
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  created() {
    this.updateDisabled();
  },
  methods: {
    debouncedUpdateDisabled: debounce(async function () {
      this.updateDisabled();
    }, 500),
    updateDisabled() {
      this.disabled = isEmpty(this.sites) || !this.period || this.isLoading;
    },
  },
  computed: {
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    period() {
      return this.$store.getters[`${this.department}/getPeriod`];
    },
  },
  watch: {
    isLoading() {
      this.debouncedUpdateDisabled();
    },
    sites() {
      this.debouncedUpdateDisabled();
    },
    period() {
      this.debouncedUpdateDisabled();
    },
  },
};
</script>

<style></style>
