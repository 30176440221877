<template>
  <div>
    <v-select
      outlined
      v-model="region"
      :items="regions"
      clearable
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Région"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "RegionFilter",
  data() {
    return {
      regions: [
        {
          id: "Auvergne-Rhone-Alpes",
          name: "Auvergne-Rhone-Alpes",
        },
        {
          id: "Bourgogne-Franche-Comte",
          name: "Bourgogne-Franche-Comte",
        },
        {
          id: "Brittany",
          name: "Bretagne",
        },
        {
          id: "Centre-Val de Loire",
          name: "Centre-Val de Loire",
        },
        {
          id: "Corsica",
          name: "Corse",
        },
        {
          id: "Grand Est",
          name: "Grand Est",
        },
        {
          id: "Hauts-de-France",
          name: "Hauts-de-France",
        },
        {
          id: "Ile-de-France",
          name: "Ile-de-France",
        },
        {
          id: "Normandy",
          name: "Normandie",
        },
        {
          id: "Nouvelle-Aquitaine",
          name: "Nouvelle-Aquitaine",
        },
        {
          id: "Occitanie",
          name: "Occitanie",
        },
        {
          id: "Pays de la Loire",
          name: "Pays de la Loire",
        },
        {
          id: "Provence-Alpes-Cote d'Azur",
          name: "Provence-Alpes-Cote d'Azur",
        },
        // {
        //   name: "Autres",
        // },
      ],
    };
  },
  computed: {
    region: {
      get() {
        return this.$store.getters["marmiton/getRegion"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateRegion", e);
      },
    },
  },
};
</script>

<style></style>
