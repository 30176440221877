export const getDatesFromPeriod = (period) => {
  const now = new Date();
  const dayOffset = 24 * 60 * 60 * 1000;
  const end = new Date(now.getTime() - 2 * dayOffset);
  const endDate = end.toISOString().split("T")[0];
  const startDate = new Date(end.getTime() - period * dayOffset)
    .toISOString()
    .split("T")[0];

  return {
    startDate,
    endDate,
  };
};
