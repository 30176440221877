<template>
  <div>
    <v-select
      outlined
      v-model="flavour"
      :items="flavours"
      clearable
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Saveur"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "FlavourFilter",
  data() {
    return {
      flavours: [
        {
          id: 1,
          name: "Sucré",
        },
        {
          id: 2,
          name: "Salé",
        },
      ],
    };
  },
  computed: {
    flavour: {
      get() {
        return this.$store.getters["marmiton/getFlavour"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateFlavour", e);
      },
    },
  },
};
</script>

<style></style>
