<template>
  <div>
    <v-text-field
      outlined
      dense
      label="Contenant"
      persistent-hint
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      v-model="textFilter"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "TextFilter",
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    textFilter: {
      get() {
        return this.$store.getters[`${this.department}/getContainsText`];
      },
      set(e) {
        this.$store.dispatch(`${this.department}/updateContainsText`, e);
      },
    },
  },
};
</script>

<style scoped></style>
