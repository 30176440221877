<template>
  <bar-chart
    :title="title"
    :colors="colors"
    :series="series"
    :labels="labels"
    :loading="loading"
    :datalabels="false"
    :yAxis="yAxis"
    :formatter="formatter"
    :outlined="true"
  />
</template>

<script>
import debounce from "lodash/debounce";

import BarChart from "@/components/common/charts/BarChart";
import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup";
import { getDatesFromPeriod } from "@/components/regie/visitors-info/utils/datesFromPeriod";

import useAxios from "@/hooks/useAxios";
import { colors } from "@/utils/constants";
import { formatNumber, formatPercent } from "@/utils/formatting";

const emptySeries = [
  {
    name: "Vues",
    data: [],
  },
];

export default {
  name: "ViewsByDay",
  props: {
    title: {
      type: String,
      require: true,
    },
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  components: {
    BarChart,
  },
  data() {
    return {
      loading: false,
      colors: [colors.views],
      series: [...emptySeries],
      labels: [],
      formatter: (val) => {
        return this.formatData(val);
      },
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => this.formatData(val),
          },
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.debouncedGetData();
  },
  methods: {
    resetData() {
      this.series = [...emptySeries];
      this.labels = [];
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    formatData(val) {
      return this.useMarmitonFilters ? formatPercent(val) : formatNumber(val);
    },
    formatResult(data) {
      if (!this.useMarmitonFilters) {
        return data;
      }

      const max = Math.max(...data);

      return data.map((val) => val / max);
    },
    getParams() {
      const { startDate, endDate } = getDatesFromPeriod(this.period);

      if (this.useMarmitonFilters) {
        return {
          start_date: startDate,
          end_date: endDate,
          ingredients_to_include: this.ingredientsToInclude,
          ingredients_to_exclude: "",
          search_text: this.searchText,
          ...(this.flavour ? { flavour: this.flavour } : {}),
          ...(this.region ? { region: this.region } : {}),
          ...(this.ageSlot ? { ageSlot: this.ageSlot } : {}),
        };
      }

      return {
        start_date: startDate,
        end_date: endDate,
        sites_id: this.sites.join(","),
        search_text: this.searchText,
      };
    },
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.sites &&
        this.sites.length > 0 &&
        this.period &&
        this.period > 0
      ) {
        this.loading = true;
        const params = this.getParams();
        const { data } = await this.axiosGet(
          this.useMarmitonFilters
            ? "/regie/marmiton/sessions-by-days"
            : "/adn-post/sessions-by-days",
          params
        );

        const series = [];
        const labels = [];
        for (const { date, views } of data) {
          series.push(views);
          labels.push(date);
        }

        this.series = [
          {
            name: "Vues",
            data: this.formatResult(series),
          },
        ];
        this.labels = labels;

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    period() {
      return this.$store.getters[`${this.department}/getPeriod`];
    },
    flavour() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getFlavour"]
        : "";
    },
    region() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRegion"]
        : "";
    },
    ageSlot() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getAgeSlot"]
        : "";
    },
    ingredientsToInclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonIngredientsToInclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
  },
  watch: {
    sites() {
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
    period() {
      this.debouncedGetData();
    },
    flavour() {
      this.debouncedGetData();
    },
    region() {
      this.debouncedGetData();
    },
    ageSlot() {
      this.debouncedGetData();
    },
    ingredientsToInclude() {
      this.debouncedGetData();
    },
    searchText() {
      this.debouncedGetData();
    },
    department() {
      this.resetData();
      this.debouncedGetData();
    },
  },
};
</script>
