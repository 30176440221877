<template>
  <div>
    <v-select
      outlined
      v-model="ageSlot"
      :items="ageSlots"
      clearable
      item-text="name"
      item-value="name"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Tranche d'âge"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "AgeFilter",
  data() {
    return {
      ageSlots: [
        {
          name: "18-24",
        },
        {
          name: "25-34",
        },
        {
          name: "35-44",
        },
        {
          name: "45-54",
        },
        {
          name: "55-64",
        },
        {
          name: "65+",
        },
      ],
    };
  },
  computed: {
    ageSlot: {
      get() {
        return this.$store.getters["marmiton/getAgeSlot"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateAgeSlot", e);
      },
    },
  },
};
</script>

<style></style>
