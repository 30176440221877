<template>
  <v-card outlined>
    <v-card-title>Répartition par sites</v-card-title>
    <v-card-text>
      <v-container v-if="loading" class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-container>
      <v-container v-else-if="categories.length === 0" class="text-center">
        {{ noDataText }}
      </v-container>
      <generic-graph
        v-else
        :series="series"
        :color="chartOptions.colors"
        :chartOptions="chartOptions"
        :chartCategories="categories"
        xaxisType="category"
        :legend="false"
      ></generic-graph>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";
import { chartColors, noDataText } from "@/utils/constants";

import GenericGraph from "@/components/common/charts/GenericGraph";

const NB_SITES_TO_DISPLAY = 20;

const emptySeries = [
  {
    name: "percent",
    data: [],
  },
];

const yAxis = (color = "") => ({
  min: 0,
  forceNiceScale: true,
  labels: {
    style: {
      fontSize: "14px",
      fontWeight: 400,
      ...(color ? { colors: color } : {}),
    },
  },
});

export default {
  name: "RepartitionBySitesChart",
  components: {
    GenericGraph,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {
      loading: false,
      noDataText,
      series: [...emptySeries],
      categories: [],
      chartOptions: {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        legend: {
          fontSize: "14px",
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        colors: chartColors,
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        yaxis: [
          {
            seriesName: "articles",
            title: {
              text: "Nb Articles",
            },
            ...yAxis(),
          },
        ],
        xaxis: {
          categories: [],
          labels: {
            rotate: -45,
          },
          style: {
            fontSize: "14px",
            fontWeight: 400,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    resetData() {
      this.series = [...emptySeries];
      this.categories = [];
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.period &&
        this.period > 0
      ) {
        const params = {
          n_days_ago: this.period,
          search_text: this.searchText,
        };

        this.loading = true;
        try {
          const { data } = await this.axiosGet(
            "/adn-post/nb-posts-by-site",
            params
          );

          const allSeries = Object.values(data);
          const labels = allSeries
            .slice(0, NB_SITES_TO_DISPLAY)
            .map(({ site_id }) => {
              const site = this.allSites.find(
                (_site) => _site.id === parseInt(site_id, 10)
              );

              return site?.name ?? site_id;
            });
          const series = allSeries
            .slice(0, NB_SITES_TO_DISPLAY)
            .map(({ nb_posts }) => nb_posts);
          const extraSites = allSeries
            .slice(NB_SITES_TO_DISPLAY)
            .map(({ nb_posts }) => nb_posts);

          labels.push("Autres");
          series.push(extraSites.reduce((acc, val) => acc + val, 0));

          this.categories = labels;
          this.series = [
            {
              name: "articles",
              data: series,
            },
          ];

          this.loading = false;
        } catch (err) {
          this.loading = false;
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    allSites() {
      return this.$store.getters["common/getSiteList"];
    },
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    period() {
      return this.$store.getters[`${this.department}/getPeriod`];
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
  },
  watch: {
    period() {
      this.debouncedGetData();
    },
    searchText() {
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
    department() {
      this.resetData();
      this.debouncedGetData();
    },
  },
};
</script>
